<template>
    <div>
        <b-navbar toggleable="lg" type="dark" variant="primary">
            <b-navbar-nav class="ml-auto">
                <b-nav-item-dropdown right v-b-tooltip.hover :title="$t('nav.language')">
                    <template #button-content><font-awesome-icon icon="language" class="fa-lg" /></template>
                    <b-dropdown-item v-for="locale in locales" :key="locale" @click="switchLocale(locale)">{{ $t("languages." + locale)}}</b-dropdown-item>
                </b-nav-item-dropdown>
            </b-navbar-nav>
        </b-navbar>
        <b-container>
            <b-card class="mt-4">
                <b-card-text class="text-center">
                    <h1>{{ $t('me.name') }}</h1>
                    <p>{{ $t('me.description') }}</p>
                </b-card-text>
            </b-card>
            <h2 class="text-center mt-4">{{ $t('workhistory.title') }}</h2>
            <b-card class="mt-4">
                <b-card-text class="text-center">
                    <h3><b>{{ $t('workhistory.3.title') }}</b> {{ $t('workhistory.3.position') }}</h3>
                    <p>
                        <i>{{ $t('workhistory.3.when') }}</i><br>
                        {{ $t('workhistory.3.description') }}<br>
                        <b-badge pill variant="info">{{ $t('skills.tags.fiberfusionsplicing') }}</b-badge>&nbsp;
                        <b-badge pill variant="info">{{ $t('skills.tags.otdr') }}</b-badge>&nbsp;
                        <b-badge pill variant="info">{{ $t('skills.tags.penetrationtesting') }}</b-badge>&nbsp;
                        <b-badge pill variant="info">{{ $t('skills.tags.accesscontrol') }}</b-badge>&nbsp;
                        <b-badge pill variant="info">{{ $t('skills.tags.monitoring') }}</b-badge>&nbsp;
                    </p>
                    <br>
                    <h3><b>{{ $t('workhistory.2.title') }}</b></h3>
                    <p>
                        <i>{{ $t('workhistory.2.when') }}</i><br>
                        <ul>
                            <li>{{ $t('workhistory.2.description.1') }}</li>
                            <li>{{ $t('workhistory.2.description.2') }}</li>
                            <li>{{ $t('workhistory.2.description.3') }}</li>
                            <li>{{ $t('workhistory.2.description.4') }}</li>
                            <li>{{ $t('workhistory.2.description.5') }}</li>
                        </ul>
                    </p>
                    <br>
                    <h3><b>{{ $t('workhistory.1.title') }}</b> {{ $t('workhistory.1.position') }}</h3>
                    <p>
                        <i>{{ $t('workhistory.1.when') }}</i><br>
                        {{ $t('workhistory.1.description') }}
                    </p>
                </b-card-text>
            </b-card>
            <h2 class="text-center mt-4">{{ $t('education.title') }}</h2>
            <b-card class="mt-4">
                <b-card-text class="text-center">
                    <h3><b>{{ $t('education.2.title') }}</b> {{ $t('education.2.course') }}</h3>
                    <p>
                        <i>{{ $t('education.2.when') }}</i>
                    </p>
                    <br>
                    <h3><b>{{ $t('education.1.title') }}</b> {{ $t('education.1.course') }}</h3>
                    <p>
                        <i>{{ $t('education.1.when') }}</i>
                    </p>
                </b-card-text>
            </b-card>
            <h2 class="text-center mt-4">{{ $t('certificates.title') }}</h2>
            <b-card class="mt-4">
                <b-card-text class="text-center">
                    <h3><b>{{ $t('certificates.vca.title') }}</b></h3>
                    <ul>
                        <li>{{ $t('certificates.vca.course.1.title') }} <i>{{ $t('certificates.vca.course.1.validto') }}</i></li>
                        <li>{{ $t('certificates.vca.course.2.title') }} <i>{{ $t('certificates.vca.course.2.validto') }}</i></li>
                    </ul>
                    <br>
                    <h3><b>{{ $t('certificates.firstaid.title') }}</b></h3>
                    <p>
                        <i>{{ $t('certificates.firstaid.when') }}</i>
                    </p>
                </b-card-text>
            </b-card>
            <h2 class="text-center mt-4">{{ $t('skills.title') }}</h2>
            <b-card class="mt-4">
                <b-card-text class="text-center">
                    <h3>{{ $t('other.1.title') }}</h3>
                    <p>
                        {{ $t('other.1.description') }}
                    </p>
                    <br>
                    <h3>{{ $t('other.2.title') }}</h3>
                    <p>
                        {{ $t('other.2.description') }}
                    </p>
                </b-card-text>
            </b-card>
            <h2 class="text-center mt-4">{{ $t('projects.title') }}</h2>
            <b-card-group columns class="mt-4">
                <b-card class="text-center">
                    <b-card-text>
                        <h3>{{ $t('projects.koelingmonitor.title') }}</h3>
                        <p>
                            {{ $t('projects.koelingmonitor.description') }}
                        </p>
                        <b-badge pill variant="primary">{{ $t('skills.tags.c') }}</b-badge>&nbsp;
                        <b-badge pill variant="primary">{{ $t('skills.tags.php') }}</b-badge>&nbsp;
                        <b-badge pill variant="primary">{{ $t('skills.tags.js') }}</b-badge>&nbsp;
                        <b-badge pill variant="success">{{ $t('skills.tags.embedded') }}</b-badge>&nbsp;
                        <b-badge pill variant="success">{{ $t('skills.tags.mariadb') }}</b-badge>&nbsp;
                        <b-badge pill variant="success">{{ $t('skills.tags.docker') }}</b-badge>&nbsp;
                        <b-badge pill variant="success">{{ $t('skills.tags.gitlab') }}</b-badge>&nbsp;
                        <b-badge pill variant="info">{{ $t('skills.tags.electronics') }}</b-badge>&nbsp;
                        <b-badge pill variant="info">{{ $t('skills.tags.pcb') }}</b-badge>&nbsp;
                        <br><br>
                        <b-button block variant="primary" class="hide-print" v-b-modal.projectskoelingmonitor>{{ $t('general.readmore') }}</b-button>
                    </b-card-text>
                </b-card>
                <b-card class="text-center">
                    <b-card-text>
                        <h3>{{ $t('projects.checkda.title') }}</h3>
                        <p>
                            {{ $t('projects.checkda.description') }}
                        </p>
                        <b-badge pill variant="primary">{{ $t('skills.tags.c') }}</b-badge>&nbsp;
                        <b-badge pill variant="primary">{{ $t('skills.tags.python') }}</b-badge>&nbsp;
                        <b-badge pill variant="primary">{{ $t('skills.tags.php') }}</b-badge>&nbsp;
                        <b-badge pill variant="primary">{{ $t('skills.tags.vue') }}</b-badge>&nbsp;
                        <b-badge pill variant="success">{{ $t('skills.tags.nfc') }}</b-badge>&nbsp;
                        <b-badge pill variant="success">{{ $t('skills.tags.embedded') }}</b-badge>&nbsp;
                        <b-badge pill variant="success">{{ $t('skills.tags.redis') }}</b-badge>&nbsp;
                        <b-badge pill variant="success">{{ $t('skills.tags.mariadb') }}</b-badge>&nbsp;
                        <b-badge pill variant="success">{{ $t('skills.tags.docker') }}</b-badge>&nbsp;
                        <b-badge pill variant="success">{{ $t('skills.tags.keycloak') }}</b-badge>&nbsp;
                        <b-badge pill variant="success">{{ $t('skills.tags.gitlab') }}</b-badge>&nbsp;
                        <b-badge pill variant="success">{{ $t('skills.tags.oauth') }}</b-badge>&nbsp;
                        <b-badge pill variant="info">{{ $t('skills.tags.electronics') }}</b-badge>&nbsp;
                        <b-badge pill variant="info">{{ $t('skills.tags.pcb') }}</b-badge>&nbsp;
                        <br><br>
                        <b-button block variant="primary" class="hide-print" v-b-modal.projectscheckda>{{ $t('general.readmore') }}</b-button>
                    </b-card-text>
                </b-card>
                <b-card class="text-center">
                    <b-card-text>
                        <h3>{{ $t('projects.as57225.title') }}</h3>
                        <p>
                            {{ $t('projects.as57225.description') }}
                        </p>
                        <b-badge pill variant="success">{{ $t('skills.tags.ipv6') }}</b-badge>&nbsp;
                        <b-badge pill variant="success">{{ $t('skills.tags.bgp') }}</b-badge>&nbsp;
                        <b-badge pill variant="success">{{ $t('skills.tags.ospf') }}</b-badge>&nbsp;
                        <b-badge pill variant="success">{{ $t('skills.tags.rpki') }}</b-badge>&nbsp;
                        <b-badge pill variant="success">{{ $t('skills.tags.netbox') }}</b-badge>&nbsp;
                        <b-badge pill variant="success">{{ $t('skills.tags.librenms') }}</b-badge>&nbsp;
                        <b-badge pill variant="success">{{ $t('skills.tags.xcp-ng') }}</b-badge>&nbsp;
                        <b-badge pill variant="success">{{ $t('skills.tags.freepbx') }}</b-badge>&nbsp;
                        <b-badge pill variant="success">{{ $t('skills.tags.mailcow') }}</b-badge>&nbsp;
                        <b-badge pill variant="success">{{ $t('skills.tags.routinator') }}</b-badge>&nbsp;
                        <b-badge pill variant="success">{{ $t('skills.tags.mikrotik') }}</b-badge>&nbsp;
                        <b-badge pill variant="success">{{ $t('skills.tags.bind9') }}</b-badge>&nbsp;
                        <br><br>
                        <b-button block variant="primary" class="hide-print" v-b-modal.projectsas57225>{{ $t('general.readmore') }}</b-button>
                    </b-card-text>
                </b-card>
                <b-card class="text-center">
                    <b-card-text>
                        <h3>{{ $t('projects.toekomstperspectief.title') }}</h3>
                        <p>
                            {{ $t('projects.toekomstperspectief.description') }}
                        </p>
                        <b-badge pill variant="primary">{{ $t('skills.tags.js') }}</b-badge>&nbsp;
                        <b-badge pill variant="primary">{{ $t('skills.tags.python') }}</b-badge>&nbsp;
                        <b-badge pill variant="primary">{{ $t('skills.tags.php') }}</b-badge>&nbsp;
                        <b-badge pill variant="primary">{{ $t('skills.tags.vue') }}</b-badge>&nbsp;
                        <b-badge pill variant="success">{{ $t('skills.tags.redis') }}</b-badge>&nbsp;
                        <b-badge pill variant="success">{{ $t('skills.tags.mariadb') }}</b-badge>&nbsp;
                        <b-badge pill variant="success">{{ $t('skills.tags.docker') }}</b-badge>&nbsp;
                        <b-badge pill variant="success">{{ $t('skills.tags.gitlab') }}</b-badge>&nbsp;
                        <b-badge pill variant="success">{{ $t('skills.tags.oauth') }}</b-badge>&nbsp;
                        <b-badge pill variant="success">{{ $t('skills.tags.traefik') }}</b-badge>&nbsp;
                        <b-badge pill variant="success">{{ $t('skills.tags.crowdsec') }}</b-badge>&nbsp;
                        <br><br>
                        <b-button block variant="primary" class="hide-print" v-b-modal.projectstoekomstperspectief>{{ $t('general.readmore') }}</b-button>
                    </b-card-text>
                </b-card>
                <b-card class="text-center">
                    <b-card-text>
                        <h3>{{ $t('projects.unifi-led.title') }}</h3>
                        <p>
                            {{ $t('projects.unifi-led.description') }}
                        </p>
                        <b-badge pill variant="primary">{{ $t('skills.tags.python') }}</b-badge>&nbsp;
                        <b-badge pill variant="info">{{ $t('skills.tags.reverseengineering') }}</b-badge>&nbsp;
                        <br><br>
                        <b-button block variant="primary" class="hide-print" v-b-modal.projectsunifi-led>{{ $t('general.readmore') }}</b-button>
                    </b-card-text>
                </b-card>
            </b-card-group>
            <br>
            <br>
            <br>
        </b-container>
        <b-modal id="projectskoelingmonitor" :title="$t('projects.koelingmonitor.modal.title')" hide-footer="true" size="xl">
            <h3>{{ $t('projects.koelingmonitor.modal.hardware.title') }}</h3>
            <p>{{ $t('projects.koelingmonitor.modal.hardware.text') }}</p>
            <h3>{{ $t('projects.koelingmonitor.modal.software.title') }}</h3>
            <p>{{ $t('projects.koelingmonitor.modal.software.text') }}</p>
        </b-modal>
        <b-modal id="projectscheckda" :title="$t('projects.checkda.modal.title')" hide-footer="true" size="xl">
            <h3>{{ $t('projects.checkda.modal.hardware.title') }}</h3>
            <p>{{ $t('projects.checkda.modal.hardware.text') }}</p>
            <h3>{{ $t('projects.checkda.modal.software.title') }}</h3>
            <p>{{ $t('projects.checkda.modal.software.text') }}</p>
        </b-modal>
        <b-modal id="projectsas57225" :title="$t('projects.as57225.modal.title')" hide-footer="true" size="xl">
            <p>{{ $t('projects.as57225.modal.text') }}</p>
        </b-modal>
        <b-modal id="projectstoekomstperspectief" :title="$t('projects.toekomstperspectief.modal.title')" hide-footer="true" size="xl">
            <p>{{ $t('projects.toekomstperspectief.modal.text') }}</p>
        </b-modal>
        <b-modal id="projectsunifi-led" :title="$t('projects.unifi-led.modal.title')" hide-footer="true" size="xl">
            <p>{{ $t('projects.unifi-led.modal.text') }}</p>
            <a href="https://www.home-assistant.io/integrations/unifiled/" target="_blank">{{ $t('projects.unifi-led.modal.link') }}</a>
        </b-modal>
    </div>
</template>
  
<script>
export default {
    name: 'App',
    methods: {
        switchLocale(locale) {
            if (this.$i18n.locale !== locale) {
                this.$i18n.locale = locale;
            }
        },
    },
    data() {
        return {
            locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(','),
        }
    },
}
</script>
  
<style>
.fade-enter-active,
.fade-leave-active {
    transition-duration: 0.3s;
    transition-property: opacity;
    transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
    opacity: 0
}

.nav-item.nav-item.nav-item a {
    color: #132351;
}

@media print { 
    .hide-print { 
        display: none !important;
    } 
} 
</style>