import Vue from 'vue'
import { createApp } from 'vue'
import App from './App.vue'
import { BootstrapVue } from 'bootstrap-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faAngleDoubleDown, faLanguage } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add( faAngleDoubleDown, faLanguage )

Vue.component('font-awesome-icon', FontAwesomeIcon)

import './app.scss'
import i18n from './i18n'

Vue.use(BootstrapVue)

Vue.config.productionTip = false

const app = createApp(App)

app.use(i18n)
app.mount("#app");
