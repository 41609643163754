export default {
  "certificates": {
    "firstaid": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EHBO"])},
      "when": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["December 2023"])}
    },
    "vca": {
      "course": {
        "1": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AV-021 - Steigerbouwer"])},
          "validto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geldig tot 19-01-2034"])}
        },
        "2": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IS-005 - Werken met zelfrijdende hoogwerker"])},
          "validto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geldig tot 12-01-2029"])}
        }
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VCA"])}
    }
  },
  "education": {
    "1": {
      "course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leercontract PC Technicus"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syntra"])},
      "when": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["December 2013 -Juni 2015"])}
    },
    "2": {
      "course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bachelor in de elektronica-ICT"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thomas More"])},
      "when": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Februari 2017 - Januari 2022"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opleidingen"])}
  },
  "general": {
    "readmore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lees meer"])}
  },
  "languages": {
    "en-US": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engels"])},
    "nl-BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nederlands"])}
  },
  "me": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Floris Van der krieken"])}
  },
  "nav": {
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taal"])}
  },
  "other": {
    "1": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In mijn vrije tijd ben ik soms bezig met het zoeken naar beveiligingsproblemen. Mijn gebruikersnaam op HackerOne is florisvdk."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bug bounty hunting"])}
    },
    "2": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik ben lid van RIPE NCC."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RIPE LIR"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overig"])}
  },
  "projects": {
    "as57225": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na verschillende keren moeten te veranderen van collocatie provider heb ik besloten om mijn eigen ip adressen te verkrijgen zodat in de toekomst deze niet meer veranderen."])},
      "modal": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het AS 57225 netwerk heeft als doel een volledig redundant autonoom systeem te zijn. Dit is momenteel nog in opbouw. Het netwerk is wel al verbonden met het internet met 1 transit connectie met een 2de connectie besteld bij 2de provider. De BGP sessies worden opgebouwd door 2 MikroTik CCR2004 routers. BGP routes worden gevalideerd met RPKI en er zijn 2 DNS servers aanwezig."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AS57225"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AS57225"])}
    },
    "checkda": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkda is een systeem voor scholen om de aanwezigheids- en middagmaaltijdadministratie tevereenvoudigen."])},
      "modal": {
        "hardware": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De kiosken voor de leerlingen maken gebruik van een Raspberry Pi met een touchscreen, een 3d geprinte behuizing en een zelf gemaakte badge lezer PCB."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hardware"])}
        },
        "software": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De Checkda software bestaat uit meerdere onderdelen. Er is een leerling en ouder portaal dat Keycloak gebruikt voor authenticatie. Er zijn 2 verschillende software versies voor op de kiosken, deze voor de leerlingen en een versie voor gebruik op het leerlingssecretariaat. Het beheer van all dit is mee verwerkt in het Toekomstperspectief platform."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Software"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkda"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checkda"])}
    },
    "koelingmonitor": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IOT apparaat en online platform voor het monitoren van koel installaties."])},
      "modal": {
        "hardware": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De zelf ontworpen printplaat voor dit product gebruik een ESP32 als microprocessor. De temperatuur wordt gemeten met een 1-wire temperatuur sensor. Om de data te blijven bewaren als de stroom uitvalt is er een lithium batterij ingebouwd. Er is ook een OLED scherm om de status van het toestel en temperatuur weer te geven."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hardware"])}
        },
        "software": {
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De firmware voor de apparaten is geschreven in C. De firmware wordt gecompileerd met behulp van GitLab CI en automatich gedownload door de apparaten. De web applicatie voor de eindgebruikers geeft alle historische data weer en heeft instellingen om meldingen te sturen via verschillende kanalen. "])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Software"])}
        },
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koelingmonitor"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koelingmonitor"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projecten"])},
    "toekomstperspectief": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toekomstperspectief is een web applicatie voor scholen om doelen te stellen en vooruitgang te volgen."])},
      "modal": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het Toekomstperspectief is gemaakt met een Vue.js frontend, PHP backend, redis cache en MariaDB database. De software opgedeeld in containers met Docker. Treafik doet TLS terminatie en CrowdSec wordt gebruikt als een extra bescherming."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toekomstperspectief"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toekomstperspectief"])}
    },
    "unifi-led": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na het reverse engineeren van de UniFi LED API heb ik een integratie geschreven voor Home Assistant."])},
      "modal": {
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Assistant pagina"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voor het nu EOL UniFi LED product heb ik een Home Assistant integratie geschreven."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UniFi LED Home Assistant integratie"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UniFi LED Home Assistant integratie"])}
    }
  },
  "skills": {
    "tags": {
      "accesscontrol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toegangscontrole"])},
      "bgp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BGP"])},
      "bind9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIND 9"])},
      "c": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["C"])},
      "crowdsec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CrowdSec"])},
      "docker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Docker"])},
      "electronics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektronica"])},
      "embedded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Embedded hardware"])},
      "fiberfusionsplicing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiber fusion splicing"])},
      "freepbx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FreePBX"])},
      "gitlab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GitLab"])},
      "ipv6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IPv6"])},
      "js": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JavaScript"])},
      "keycloak": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keycloak"])},
      "librenms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LibreNMS"])},
      "mailcow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mailcow"])},
      "mariadb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MariaDB"])},
      "mikrotik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MikroTik"])},
      "monitoring": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monitoring"])},
      "netbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NetBox"])},
      "nfc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFC"])},
      "oauth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OAuth"])},
      "ospf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OSPF"])},
      "otdr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTDR"])},
      "pcb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Printplaat ontwerpen"])},
      "penetrationtesting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penetration testing"])},
      "php": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PHP"])},
      "python": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Python"])},
      "redis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redis"])},
      "reverseengineering": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reverse engineering"])},
      "routinator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Routinator"])},
      "rpki": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RPKI"])},
      "traefik": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traefik"])},
      "vue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vue.js"])},
      "xcp-ng": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XCP-ng"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kennis"])}
  },
  "workhistory": {
    "1": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support voor consumenten en bedrijven op afstand, bij de klant en in de winkel. Gaande van uitleg geven, klaarzetten van nieuwe werkstations en het configureren, onderhouden en problemen oplossen van windows server omgevingen."])},
      "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technieker"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aksioma"])},
      "when": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["December 2013 - Oktober 2015"])}
    },
    "2": {
      "description": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het ontwerpen, plaatsen en onderhouden van netwerken voor klanten. Kleine tot grote installaties. (Horeca, scholen, campings, dierentuin)."])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web Hosting."])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het programmeren van diverse applicaties."])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het ontwerpen, plaatsen en onderhouden van domotica en elektrische installaties. Gaande van verlichting tot volledige sturing van verwarming."])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitaliseren van bedrijfsprocessen."])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zelfstandige"])},
      "when": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Februari 2017 - Nu"])}
    },
    "3": {
      "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datacenter service technieker"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LCL Data Centers"])},
      "when": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oktober 2023 - Nu"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ervaring"])}
  }
}